<template>
  <KTooltip
    :id="componentId"
    :key="computedPlacement"
    class="portal-k-tooltip"
    :data-testid="dataTestId"
    :max-width="maxWidth"
    :placement="computedPlacement"
    :text="text"
  >
    <template
      v-if="!!slots.content"
      #content
    >
      <MDCSlot
        name="content"
        unwrap="p"
      />
    </template>
    <template
      v-if="!!slots.default"
      #default
    >
      <div>
        <MDCSlot
          name="default"
          unwrap="p"
        />
      </div>
    </template>
  </KTooltip>
</template>

<script setup lang="ts">
import type { PortalKTooltipProps } from '#imports'
import { PopPlacementsArray } from '@kong/kongponents'
import type { PopPlacements } from '@kong/kongponents'
import { KUI_COLOR_TEXT_INVERSE, KUI_COLOR_BACKGROUND_NEUTRAL_STRONGEST, KUI_BORDER_RADIUS_20, KUI_SPACE_30 } from '@kong/design-tokens'

const {
  // KTooltip props
  text = '',
  placement = 'bottom',
  // Shared props
  backgroundColor = `var(--kui-color-background-neutral-strongest, ${KUI_COLOR_BACKGROUND_NEUTRAL_STRONGEST})`,
  border = 'none',
  borderRadius = `var(--kui-border-radius-20, ${KUI_BORDER_RADIUS_20})`,
  color = `var(--kui-color-text-inverse, ${KUI_COLOR_TEXT_INVERSE})`,
  display = 'inline-flex',
  maxWidth = 'none',
  padding = `var(--kui-space-30, ${KUI_SPACE_30})`,
  styles = '',
} = defineProps<PortalKTooltipProps>()

const attrs = useAttrs()
// Inject any custom `props.styles` scoped by the `componentId` into the document head
const { componentId } = useCustomStyles(computed(() => styles), attrs.id as string)
const dataTestId = computed((): string => 'data-testid' in attrs ? attrs['data-testid'] as string : 'portal-k-tooltip')

const slots = defineSlots<{
  default(): any
  content(): any
}>()

// Compute the appearance and size based on the provided props
const computedPlacement = computed((): PopPlacements => Object.values(PopPlacementsArray).includes(placement) ? placement : 'bottom')
</script>

<style lang="scss" scoped>
.portal-k-tooltip.k-popover {
  display: v-bind('display');

  :deep(.popover-container) {
    background-color: v-bind('backgroundColor');
    border: v-bind('border');
    border-radius: v-bind('borderRadius');
    padding: v-bind('padding');

    .popover-content {
      color: v-bind('color');
    }
  }
}
</style>
